import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';

const graphqlClient = new ApolloClient({
  uri: 'https://api.quran.com/graphql',
  cache: new InMemoryCache()
});

export default graphqlClient;
