import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import logo from './logo.svg';
import graphqlClient from './graphql.js';
import './App.css';

const nrOfAyah = 3;

const SIMPLE_REMOVAL_REGEXP = /[^\u0627-\u063a\u0641-\u064a]/g

function App() {
  const [loadingRandomize, setLoadingRandomize] = useState(true);
  const [chapterId, setChapterId] = useState(0);
  const [ayahId, setAyahId] = useState(0);

  const [ayahIndex, setAyahIndex] = useState(0);
  const [wordIndex, setWordIndex] = useState(3);
  const [charIndex, setCharIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');

  const { loadingData, error, data } = useQuery(gql`{
    chapter(id: ${chapterId}) {
      nameSimple
    }
    verses(chapterId: ${chapterId}, offset: ${ayahId-1}, limit: ${nrOfAyah}) {
      textImlaeiSimple
      words {
        textImlaei
      }
    }
  }`, {
    skip: loadingRandomize,
    onCompleted: res => console.log(res)
  });

  console.log(loadingRandomize);
  console.log(loadingData);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('surah')) {
      graphqlClient
        .query({
          query: gql`
	    {
	      chapter(id: ${searchParams.get('surah')}) {
	        versesCount
	      }
	    }
	  `
	})
        .then(result => {
          setChapterId(searchParams.get('surah'));
          setAyahId(Math.floor(Math.random() * result.data.chapter.verseCount + 1));
          setLoadingRandomize(false);
	});
    } else if (searchParams.has('juz')) {
      graphqlClient
        .query({
          query: gql`
	    {
	      juzs {
	        verseMapping
	      }
	    }
	  `
	})
        .then(result => {
          const verseMapping = result.data.juzs[searchParams.get('juz')];
          const chapter = verseMapping.keys()[Math.floor(Math.random() * verseMapping.keys().length)];
          const [ayahBegin, ayahEnd] = verseMapping[chapter].split('-').map(x => parseInt(x));
          const ayah = Math.floor(Math.random() * (ayahEnd - ayahBegin + 1) + ayahBegin);

          setChapterId(chapter);
          setAyahId(ayah);
          setLoadingRandomize(false);
	});
    }
  }, []);


  if (loadingRandomize) return 'Generating ayah...';

  if (loadingData) return 'Getting the ayah...';

  if (error) return `Error! ${error.message}`;

  if (ayahIndex === nrOfAyah) return 'Congratulations!';

  function handleInput(e) {
    const currentAyah = data.verses[ayahIndex].textImlaeiSimple;
    const currentWord = currentAyah.split(' ')[wordIndex] + ' ';
    setCurrentText(e.target.value);
    console.log(currentWord);
    console.log(e.target.value);

    var idx = 0;
    while (idx < currentWord.length && e.target.value[idx] === currentWord[idx]) {
      idx++;
    }
    setCharIndex(idx % currentWord.length);
    const wordCompleted = (idx === currentWord.length);

    if (wordCompleted) {
        e.target.value = '';
        setCurrentText('');
    }

    idx = wordIndex + wordCompleted;
    setWordIndex(idx % currentAyah.split(' ').length);
    const ayahCompleted = (idx === currentAyah.split(' ').length);

    idx = ayahIndex + ayahCompleted;
    setAyahIndex(idx);
  }

  var ayahDOM = [];
  var wordDOM;
  for (let i = 0; i < ayahIndex; i++) {
    wordDOM = [];
    for (let word of data.verses[i].words) {
      wordDOM.push(<span>{word.textImlaei} </span>);
    }
    ayahDOM.push(<div>{wordDOM}</div>);
  }
  wordDOM = [];
  for (let i = 0; i < wordIndex; i++) {
    var word = data.verses[ayahIndex].words[i];
    wordDOM.push(<span>{word.textImlaei} </span>);
  }
  var lastWord = data.verses[ayahIndex].words[wordIndex];
  wordDOM.push(<span>{lastWord.textImlaei.replace(SIMPLE_REMOVAL_REGEXP, '').slice(0, charIndex)}</span>);
  wordDOM.push(<span style={{color:'red'}}>{currentText.slice(charIndex)}</span>);
  ayahDOM.push(<div>{wordDOM}</div>);

  return (
    <>
      <div>{ayahDOM}</div>
      <div>{ayahIndex}, {wordIndex}, {charIndex}</div>
      <div>{data.verses[ayahIndex].textImlaeiSimple}</div>
      <input onChange={handleInput} autoFocus/>
    </>
  );
}

/*
function AppOriginal() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/

export default App;
